import payload_plugin_tvxVKWJFjo from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@11.11.1_firebase@10.14.1_magicast_m7lqhhccw6jjivxxpckses2zle/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_fMeXSalLK7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TFxeBEySpS from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N3GbN8m57B from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_k0VIH8xSC0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NPwbyXbswE from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7i4P1IMM8b from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bZ3a5RrfI4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_NjftaKmj9i from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_wdFwcYHVns from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SzGeR4e3G0 from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@8.1.0_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.28.1_vue-router@4.5.0_vue@3.5.13_typescript@5.7.2__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import switch_locale_path_ssr_2JQwpzpaVZ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XnrJtd913a from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import slideovers_yfw2u5rL9S from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14.0__b4h2vvxmst7yypds3agjejngbe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_AmyyieIAXI from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14.0__b4h2vvxmst7yypds3agjejngbe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_AfHupIG2iv from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14.0__b4h2vvxmst7yypds3agjejngbe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ZG7qFIpc9r from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_t7XCPqvNvC from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.2_terser@5.37.0__zrqg3xrjwjm5zvnfiqfgt4c2le/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_p9KGT4uXma from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.28.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_X6KOFMr8F8 from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@11.11.1_firebase@10.14.1_magicast_m7lqhhccw6jjivxxpckses2zle/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import scrollbars_client_LXYBMYN2No from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14_42pdux2y3y2274mi2sx5pk7jce/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_DTJ8L5buBi from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14_42pdux2y3y2274mi2sx5pk7jce/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_LkiwQU0X1f from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14_42pdux2y3y2274mi2sx5pk7jce/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import ChartJsPlugin_ZT7WXYDpLm from "/vercel/path0/plugins/ChartJsPlugin.ts";
import Vue3Lottie_client_bMZiOS6AB0 from "/vercel/path0/plugins/Vue3Lottie.client.ts";
import recaptcha_85gNSCNFUU from "/vercel/path0/plugins/recaptcha.ts";
export default [
  payload_plugin_tvxVKWJFjo,
  revive_payload_client_fMeXSalLK7,
  unhead_TFxeBEySpS,
  router_N3GbN8m57B,
  payload_client_k0VIH8xSC0,
  navigation_repaint_client_NPwbyXbswE,
  check_outdated_build_client_7i4P1IMM8b,
  chunk_reload_client_bZ3a5RrfI4,
  plugin_vue3_NjftaKmj9i,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wdFwcYHVns,
  plugin_SzGeR4e3G0,
  switch_locale_path_ssr_2JQwpzpaVZ,
  i18n_XnrJtd913a,
  plugin_6Ph1iQzNHk,
  slideovers_yfw2u5rL9S,
  modals_AmyyieIAXI,
  colors_AfHupIG2iv,
  plugin_client_ZG7qFIpc9r,
  plugin_t7XCPqvNvC,
  plugin_p9KGT4uXma,
  plugin_client_X6KOFMr8F8,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  scrollbars_client_LXYBMYN2No,
  presets_DTJ8L5buBi,
  variables_LkiwQU0X1f,
  ChartJsPlugin_ZT7WXYDpLm,
  Vue3Lottie_client_bMZiOS6AB0,
  recaptcha_85gNSCNFUU
]