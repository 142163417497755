import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.5.0_magicast@0.3.5_rollup@_iep64rfl43izx7yi6uy43md3j4/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_sortablejs@1.14_42pdux2y3y2274mi2sx5pk7jce/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}